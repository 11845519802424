'use strict';
import { IrscCache } from './irsc-cache';

export class Category extends IrscCache(Object) {
    constructor($elem: HTMLElement, opts: any) {
        super();
        this.elem = $elem;
        this.opts = opts;
        this.jsonKey = 'programCategories';
        this.key = 'program-categories';
        this.perChunk = this.opts.perChuck || 1;
        this.categoryTemplate = document.querySelector(this.opts.irscProgramCategoryTemplate.id).innerHTML;
        this.categoryImgSectionTemplate = document.querySelector(this.opts.irscCategoryImgSectionTemplate.id).innerHTML;
        this.dataUrl = '/_resources/data/emsicc-service.php?action=program_categories';
        this.categories = null;
        this.loadCategories();
    }

    private async loadCategories() {
        const categories: any = await this.get();
        this.categories = categories.data;
        this.elem.innerHTML = this.buildSection();
    }

    private buildCategoryList() {
        return this.categories.map((category) => {
            let templateHtml = this.categoryTemplate;
            const tmpCtx = {
                description: category.attributes.description,
                irscAlt: 'image placeholder',
                irscSrc: 'https://www.irsc.edu/_resources/images/photobox-1.jpg',
                slug: category.attributes.slug,
            };

            this.opts.irscProgramCategoryTemplate.props.forEach((prop) => {
                templateHtml = templateHtml.replace(new RegExp(`{{${prop}}}`, 'g'), tmpCtx[prop] || '');
            });

            return templateHtml;
        }).join();
    }

    private buildSection() {
        const categories = this.buildCategoryList();
        const tmpCtx = {
            categories,
        };

        let templateHtml = this.categoryImgSectionTemplate;

        this.opts.irscCategoryImgSectionTemplate.props.forEach((prop) => {
            templateHtml = templateHtml.replace(new RegExp(`{{${prop}}}`, 'g'), tmpCtx[prop] || '');
        });

        return templateHtml;
    }
}

