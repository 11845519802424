'use strict';
import { IrscCache } from './irsc-cache';

export class GuidedPathwayTrackSemester extends IrscCache(Object) {

    constructor($elem: HTMLElement, opts: any) {
        super();
        this.elem = $elem;
        this.opts = opts;
        this.hidePaginationEventName = this.opts.hidePaginationEventName;
        this.semesterContainer = this.elem.querySelector('[data-pathway-track]');
        this.pagination = this.elem.querySelector('[data-pathway-pagination]');
        this.paginationPagesTemplateHtml = document.querySelector(this.opts.paginationPagesTemplate.id).innerHTML;
        this.paginationPageTemplateHtml = document.querySelector(this.opts.paginationPageTemplate.id).innerHTML;
        this.semesters = this.opts.semesters;
        this.creditHoursTitle = this.opts.creditHoursTitle;
        this.totalPages = this.semesters.length;
        this.trackNotes = this.opts.trackNotes;
        this.templateHtml = document.querySelector(this.opts.pathwayTrackSemesterTemplate.id).innerHTML;
        this.courseTemplateHtml = document.querySelector(this.opts.pathwayTrackSemesterCourseTemplate.id).innerHTML;
        this.currentPage = 1;
        this.renderSemesters();
    }

    private renderCourses(semester: any) {
        let html = '';
        semester.courses.forEach((course: any) => {
            let courseTemplateHtml = this.courseTemplateHtml;
            this.opts.pathwayTrackSemesterCourseTemplate.props.forEach((prop: string) => {
                if (
                    course.hasOwnProperty('isRequired') && course.isRequired === true
                    ) {
                        course.isRequired = 'course-is-required';
                }

                courseTemplateHtml = courseTemplateHtml.replace(new RegExp(`{{${prop}}}`, 'g'), course[prop] || '');
            });
            html += courseTemplateHtml;
        });
        return html;
    }

    private setNextPreviousAccess(targetPage: any) {
        if (targetPage === this.totalPages) {
            this.pagination.querySelector('.next').classList.add('disabled');
        } else if (targetPage < this.totalPages) {
            this.pagination.querySelector('.next').classList.remove('disabled');
        }

        if (targetPage > 1) {
            this.pagination.querySelector('.previous').classList.remove('disabled');
        } else {
            this.pagination.querySelector('.previous').classList.add('disabled');
        }
    }

    private getCurrentPage(action) {
        let targetPage = this.currentPage;

        if (action === 'next') {
            if (this.currentPage !== this.totalPages) {
                targetPage = +this.currentPage + 1;
            }
        } else {
            if (this.currentPage !== 1) {
                targetPage = +this.currentPage - 1;
            }
        }

        this.setNextPreviousAccess(targetPage);
        return targetPage;
    }

    private previousSemester() {
        this.paginateSemester(this.getCurrentPage('previous'));
    }

    private nextSemester() {
        this.paginateSemester(this.getCurrentPage('next'));
    }


    private paginateSemester(pageNum) {
        pageNum = +pageNum;

        if (pageNum !== this.currentPage) {
            // get active page (view) as remove active class
            const currentSemester = this.semesterContainer.querySelector('.active');

            currentSemester.classList.add('d-none');
            currentSemester.classList.remove('active');

            // get active page (paginator) as remove active class
            const currentPage = this.pagination.querySelector('.active');

            currentPage.classList.remove('active');

            // get incoming target page
            //     set page (visiblity ) in view
            //     set page (visibility) in paginator
            //     set current-page attribute in paginator
            this.currentPage = pageNum;

            const targetSelector = `[data-page="${pageNum}"]`;
            this.setNextPreviousAccess(pageNum);

            const targetSemester = this.semesterContainer.querySelector(targetSelector);

            const targetPage = this.pagination.querySelector(targetSelector.replace('page', 'semester'));
            targetSemester.classList.remove('d-none');
            targetSemester.classList.add('active');
            targetPage.classList.add('active');
        }
    }

    private buildSemesterPagination() {
        let templateHtml = this.paginationPagesTemplateHtml;
        const tmpCtx = {
            pages: this.renderPages(),
        };

        this.opts.paginationPagesTemplate.props.forEach((prop: any) => {
            templateHtml = templateHtml.replace(new RegExp(`{{${prop}}}`, 'g'), tmpCtx[prop] || '');
        });

        return templateHtml;
    }

    private renderPages() {
        const pages = [];
        for (let i=0; i <= this.semesters.length - 1; i++) {
            let templateHtml = this.paginationPageTemplateHtml;
            const tmpCtx = {
                active: i === 0 ? 'active' : '',
                pageNum: i + 1,
            };

            this.opts.paginationPageTemplate.props.forEach((prop) => {
                templateHtml = templateHtml.replace(new RegExp(`{{${prop}}}`, 'g'), tmpCtx[prop] || '');
            });
            pages.push(templateHtml);
        }
        return pages.join('');
    }

    private buildNotes() {
        let html = '';

        this.trackNotes.forEach((note: string) => {
            const key = Object.keys(note)[0];
            html += `<p>${note[key]}</p>`;
        });

        return html;
    }

    private renderSemesters() {
        const semesters = this.semesters.map((semester: any, idx: number) => {
            let templateHtml = this.templateHtml;
            const tmpCtx = {
                courses: this.renderCourses(semester),
                creditHoursTitle: this.creditHoursTitle,
                page: idx + 1,
                totalCreditHours: semester.totalCreditHours,
                trackNotes: this.buildNotes(),
            };

            this.opts.pathwayTrackSemesterTemplate.props.forEach((prop: string) => {
                templateHtml = templateHtml.replace(new RegExp(`{{${prop}}}`, 'g'), tmpCtx[prop] || '');
            });
            return templateHtml;
        }).join('');
        this.semesterContainer.innerHTML = semesters;

        // get the first page and remove the d-none and set active
        this.semesterContainer.querySelector('[data-page="1"]').classList.remove('d-none');
        this.semesterContainer.querySelector('[data-page="1"]').classList.add('active');

        // inject paginator
        if (this.totalPages > 1) {
            this.pagination.innerHTML = this.buildSemesterPagination();
            // setup bindings
            this.pagination.querySelector('[data-previous]').addEventListener('click', () => this.previousSemester());
            this.pagination.querySelector('[data-next]').addEventListener('click', () => this.nextSemester());

            const pages = Array.prototype.slice.apply(this.pagination.querySelectorAll('[data-page]'));
            pages.forEach((page: HTMLElement) => {
                page.addEventListener('click', (e: any) => {
                    this.paginateSemester(e.currentTarget.dataset.page);
                });
            });
        } else {
            this.elem.dispatchEvent(new CustomEvent(this.hidePaginationEventName, {
                bubbles: true,
                detail: {
                    hide: true,
                },
            }));
        }
    }
}
