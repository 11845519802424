'use strict';
import { IrscCache } from './irsc-cache';
export class Rotator extends IrscCache(Object) {
    constructor(Chart: any, $elem: HTMLElement, opts: any) {
        super();
        this.Chart = Chart;
        this.elem = $elem;
        this.opts = opts;
        this.key = this.elem.id;
        this.parentKey = this.opts.parentKey;
        this.credential = this.opts.credential;
        this.chart = null;
        this.currentSlide = 0;
        this.slides = null;
        this.totalSlides = 0;
        this.currentSlideHTML = null;
        this.slideTemplateHTML = document.querySelector(this.opts.slidesTemplate.id).innerHTML;
        this.elem.innerHTML += document.querySelector(this.opts.controlsTemplate.id).innerHTML;
        this.projectionId = this.opts.projectionId;
        this.currentChartCtx = null;
        this.chart = null;
        this.elem.querySelector('.rotator-previous').addEventListener('click', () => this.previous());
        this.elem.querySelector('.rotator-next').addEventListener('click', () => this.next());

        this.initialLoad = true;

        this.loadSlides();
    }

    private async createProjection() {
        let data: any = await this.getOther(this.currentChartCtx);
        console.log(data);
        // TODO: Initial load is null fix!
        data = data.data[0].data.attributes.employment;
        const employments = [];
        const labels = [];
        data.forEach((emp: any) => {
            employments.push(emp.number);
            labels.push(emp.year);
        });

        // poor man's dependency injected Chart.js ;p
        this.chart = new this.Chart(this.projectionId, {
            data: {
                datasets: [{
                    backgroundColor: [
                        'rgba(0, 75, 184, 0.5)',
                    ],
                    borderColor: [
                        'rgba(255, 241, 108,1)',
                    ],
                    borderWidth: 1,
                    data: employments,
                    label: '# of number employed.',
                }],
                labels,
            },
            options: {
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero:false,
                        },
                    }],
                },
            },
            type: 'line',
        });
    }

    private async loadSlides() {
        let data = await this.getOther(this.parentKey);
        data = data.data;

        if (!data) {
            document.querySelector(`#level-${this.key}`).dispatchEvent(this.opts.loadParentDataEvent);
            data = await this.getOther(this.parentKey);
        }

        this.slides = data[this.credential][this.opts.careerIndex].attributes.careers;
        this.totalSlides = this.slides.length - 1;
        this.rotateSlide();
    }

    private next() {
        if (this.currentSlide >= this.totalSlides) {
            this.currentSlide = 0;
        } else {
            this.currentSlide++;
        }

        this.rotateSlide();
    }

    private previous() {
        if (this.currentSlide <= 0) {
            this.currentSlide = this.totalSlides;
        } else {
            this.currentSlide--;
        }

        this.rotateSlide();
    }

    private _render() {
        this.elem
        .querySelector('.rotator-slides')
        .innerHTML = this.currentSlideHTML;
        this.createProjection();
        this.elem.style.height = `${this.elem.scrollHeight - 18}px`;
        this.elem.style.opacity = 1;
        setTimeout(() => {
            this.elem.style.height = '';
            this.elem.style.opacity = '';
        }, 350);
    }

    private rotateSlide() {
        this.elem.style.height = `${this.elem.scrollHeight}px`;
        this.elem.style.opacity = 0;
        this.renderHTML(this.slides[this.currentSlide]);
        if (this.initialLoad) {
            setTimeout(() => {
                this._render();
                this.initialLoad = false;
            }, 800);
        } else {
            this._render();
        }
    }

    private async getCareerData(career: string) {
        const key = `career-${career}`;
        const url = `/_resources/data/emsicc-service.php?action=program_careers&careers=${career}`;
        const jsonKey = 'programCareers';

        let data = await this.getOther(key);

        if (!data) {
            await this.fetch(key, url, jsonKey);
            data = await this.getOther(key);
        }

        data = data.data;
        // data is always an array, in this case we are expecting one in length
        return data[0];
    }

    private async getJobCareerData(career: string) {
        const key = `job-${career}`;
        const url = `/_resources/data/emsicc-service.php?action=jobs&careers=${career}`;
        const jsonKey = 'jobs';

        let data: any = await this.getOther(key);

        if (!data) {
            // console log ('Either not previous loaded or stale!');
            await this.fetch(key, url, jsonKey);
            data = await this.getOther(key);
        }

        // data is always an array, in this case we are expecting one in length
        // this looks horrible!!!! clean this up at some point!
        // console log (data);
        data = data.data[0].data;

        data.attributes.companies = data.attributes.companies.filter((company: any) =>
            company.name !== '[Unknown]',
        );
        return data;
    }

    private renderJobsListing(jobs: any) {
        // TODO: fix this later, to many job postings for the current layout
        const tmpJobs = jobs.attributes.companies.slice(0, this.opts.jobListingLimit || 10);
        const templateHtml = document.querySelector(this.opts.jobListingTemplate.id).innerHTML;
        return tmpJobs.map((job) => {
            let tmp = templateHtml;
            this.opts.jobListingTemplate.props.forEach((prop) => {
                tmp = tmp.replace(new RegExp(`{{${prop}}}`), job[prop] || '');
            });
            return tmp;
        }).join('');
    }

    private async renderHTML(ctx: any) {
        ctx = await this.getCareerData(ctx);
        const jobs = await this.getJobCareerData(ctx.data.id);
        const jobListing = this.renderJobsListing(jobs);
        this.currentChartCtx = `career-${ctx.data.id}`;

        if (ctx) {
            const amount = ctx.data.attributes['median-earnings'];
            const $earnings = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);

            let templateHtml = this.slideTemplateHTML;

            const tmpCtx = {
                earnings: $earnings,
                jobListing,
                projectionId: this.projectionId,
                title: ctx.data.attributes['humanized-title'],
            };

            this.opts.slidesTemplate.props.forEach((prop) => {
                templateHtml = templateHtml.replace(new RegExp(`{{${prop}}}`, 'g'), tmpCtx[prop] || '');
            });

            this.currentSlideHTML = templateHtml;

        } else {
            // console log (ctx);
        }
    }
}
