'use strict';
import { IrscCache } from './irsc-cache';
import { GuidedPathwayTrackSemester } from './irsc-pathway-track-semester';

export class GuidedPathwayTrack extends IrscCache(Object) {

    constructor($elem: HTMLElement, opts: any) {
        super();
        this.elem = $elem;
        this.opts = opts;
        this.tracks = this.opts.tracks;
        this.toggleEventName = this.opts.toggleEventName;
        this.trackId = this.opts.pathwayTrackId;
        this.trackNotes = null;
        this.templateHtml = document.querySelector(this.opts.pathwayTrackTemplate.id).innerHTML;
        this.currentTrack = null;
        this.renderedTracks = {
            fullTime: null,
            partTime: null,
        };
        this.partTimeSemesterClass = null;
        this.fullTimeSemesterClass = null;

        addEventListener(this.opts.toggleEventName, (e) => this.toggleTracks(e));

        this.hidePaginationEventName = `hide-pagination-${this.trackId}`;

        addEventListener(this.hidePaginationEventName, (e: any) => {
            try {
                if (e.detail.hide) {
                    this.elem.querySelector('.pathway-track-pagination').classList.add('d-none');
                }
            } catch (err) {
                console.log(e);
            }

        });
        this.renderTracks();
    }

    // private async loadTracks() {
    //     this.tracks = await this.getOther(this.trackId);
    //     this.renderTracks();
    // }

    private renderComponent(templateHtml: any, props: any, ctx: any) {
        props.forEach((prop: any) => {
            templateHtml = templateHtml.replace(new RegExp(`{{${prop}}}`, 'g'), ctx[prop] || '');
        });
        return templateHtml;
    }

    private renderTracks() {
        const templateHtml = this.templateHtml;
        let html = '';
        let tmpCtx = {};

        tmpCtx = {
            isPartTime: this.tracks.fullTime.isPartTime ? 'true' : 'false',
            trackId: this.trackId,
        };

        html = this.renderComponent(templateHtml, this.opts.pathwayTrackTemplate.props, tmpCtx);

        if (this.tracks.partTime) {
            tmpCtx = {
                isPartTime: this.tracks.partTime.isPartTime ? 'true' : 'false',
                trackId: this.trackId,
            };

            html += this.renderComponent(templateHtml, this.opts.pathwayTrackTemplate.props, tmpCtx);
        }

        this.elem.innerHTML = html;

        // setup track containers and pass initial setup, semester classes
        if (this.tracks.partTime) {
            this.renderedTracks.partTime = this.elem.querySelector('[data-is-part-time="true"]');

            this.partTimeSemesterClass = new GuidedPathwayTrackSemester(this.renderedTracks.partTime, {
                creditHoursTitle: this.opts.creditHoursTitle,
                hidePaginationEventName: this.hidePaginationEventName,
                paginationPageTemplate: this.opts.paginationPageTemplate,
                paginationPagesTemplate: this.opts.paginationPagesTemplate,
                pathwayTrackSemesterCourseTemplate: this.opts.pathwayTrackSemesterCourseTemplate,
                pathwayTrackSemesterTemplate: this.opts.pathwayTrackSemesterTemplate,
                semesters: this.tracks.partTime.semesters,
                trackNotes: this.tracks.partTime.notes,
            });

            this.renderedTracks.partTime.classList.add('d-none');
        }

        this.renderedTracks.fullTime = this.elem.querySelector('[data-is-part-time="false"]');

        this.fullTimeSemesterClass = new GuidedPathwayTrackSemester(this.renderedTracks.fullTime, {
            creditHoursTitle: this.opts.creditHoursTitle,
            hidePaginationEventName: this.hidePaginationEventName,
            paginationPageTemplate: this.opts.paginationPageTemplate,
            paginationPagesTemplate: this.opts.paginationPagesTemplate,
            pathwayTrackSemesterCourseTemplate: this.opts.pathwayTrackSemesterCourseTemplate,
            pathwayTrackSemesterTemplate: this.opts.pathwayTrackSemesterTemplate,
            semesters: this.tracks.fullTime.semesters,
            trackNotes: this.tracks.fullTime.notes,
        });

        this.renderedTracks.fullTime.classList.add('active');

        this.currentTrack = this.renderedTracks.fullTime;
    }

    private toggleTracks(e: any) {
        const dNone = 'd-none';
        const dPrintNone = 'd-print-none';

        this.currentTrack.classList.remove('active');

        if (e.detail.showPartTime) {
            this.currentTrack = this.renderedTracks.partTime;
            this.renderedTracks.fullTime.classList.add(dNone, dPrintNone);
        } else {
            this.currentTrack = this.renderedTracks.fullTime;
            this.renderedTracks.partTime.classList.add(dNone, dPrintNone);
        }

        this.currentTrack.classList.remove(dNone, dPrintNone);
        this.currentTrack.classList.add('active');
    }
}
