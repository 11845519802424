'use strict';
import { IrscCache } from './irsc-cache';

import { GuidedPathwayTrack } from './irsc-pathway-track';

export class GuidedPathway extends IrscCache(Object) {
    constructor($elem: HTMLElement, opts: any) {
        super();
        this.elem = $elem;
        this.elem.classList.add('container', 'pathway-card', 'card-body', 'program-data');
        this.elem.dataset.display = '';
        this.opts = opts;
        this.pathwayCode = this.elem.dataset.pathwayCode;
        this.toggleEventName = `pathwayToggle-${this.opts.idx}`;
        this.key = `guided-pathway-${this.pathwayCode}`;
        this.jsonKey = 'programs';
        this.dataUrl = `/_resources/data/emsicc-service.php?action=programs&codes=${this.pathwayCode}`;
        this.templateHtml = document.querySelector(this.opts.guidedPathwayTemplate.id).innerHTML;
        this.tracks = null;
        this.title = null;
        this.idx = `pathway-${this.pathwayCode}-${this.opts.idx}`;
        this.showPartTime = true;
        this.tracksChild = null;
        this.loadPathwayData();
    }

    private async loadPathwayData(): Promise<void> {
        let data: any = await this.get();
        data = data.data[0];

        this.tracks = data.irsc;
        console.log(this.tracks);

        if (!this.tracks || this.tracks.length === 0 || this.tracks === [] || this.tracks === undefined) {
            this.elem.innerHTML = `
            <p>For Academic Plan/Guided Pathway information, please consult with your advisor.</p>
            `;
        } else {
            const trackData = {
                fullTime: null,
                idx: this.idx,
                partTime: null,
                pathwayCode: this.pathwayCode,
            };

            this.tracks.forEach((track: any, idx: number) => {
                if (this.setPartTimeTrack(track, idx)) {
                    trackData.partTime = track;
                } else {
                    trackData.fullTime = track;
                }

                this.title = track.title;
            });

            this.tracks = await this.save(this.idx, trackData);
            this.tracks = this.tracks.data;
            this.showPartTime = this.tracks.partTime !== null;
            this.renderPathway();

            this.elem.querySelectorAll('.print-button').forEach((printBtn: any) => {
                printBtn.addEventListener('click', () => this.printProgram());
            });
        }

    }

    private setPartTimeTrack(track: any, idx: number): boolean {
        const isPartTime = track.isPartTime;
        if (isPartTime) {
            this.partTimeTrackNum = idx;
        }
        return isPartTime;
    }

    private renderPathway(): void {
        let templateHtml = this.templateHtml;
        const toggleId = `pathway-toggle-${this.pathwayCode}-${this.opts.idx}`;
        const tmpCtx = {
            toggleId: `pathway-toggle-${this.pathwayCode}-${this.opts.idx}`, // use instance idx
            tracks: this.idx,
        };

        this.opts.guidedPathwayTemplate.props.forEach((prop: string) => {
            templateHtml = templateHtml.replace(new RegExp(`{{${prop}}}`, 'g'), tmpCtx[prop] || '');
        });

        this.elem.innerHTML = templateHtml;

        const toggle = this.elem.querySelector(`#${toggleId}`);

        if (!this.showPartTime) {
            toggle.parentNode.classList.add('d-none');
        } else  {
            toggle.parentNode.classList.remove('d-none');
            toggle.addEventListener('click', (e: any) => this.toggleTrack(e));
        }

        // once the shell container is created
        // create a new instance of Track and let
        // it handle from there
        const tracksElem = this.elem.querySelector('[data-tracks]');
        this.tracksChild = new GuidedPathwayTrack(tracksElem, {
            creditHoursTitle: this.opts.creditHoursTitle,
            paginationPageTemplate: this.opts.paginationPageTemplate,
            paginationPagesTemplate: this.opts.paginationPagesTemplate,
            pathwayTrackId: this.idx,
            pathwayTrackSemesterCourseTemplate: this.opts.pathwayTrackSemesterCourseTemplate,
            pathwayTrackSemesterTemplate: this.opts.pathwayTrackSemesterTemplate,
            pathwayTrackTemplate: {
                id: '#irsc-pathway-track-tmp',
                props: [
                    'trackId',
                    'isPartTime',
                ],
            },
            toggleEventName: this.toggleEventName,
            tracks: this.tracks,
        });
    }

    private toggleTrack(e: MouseEvent): void {
        const toggle = e.currentTarget as any;
        toggle.dispatchEvent(new CustomEvent(this.toggleEventName, {
            bubbles: true,
            detail: {
                showPartTime: toggle.checked,
            },
        }));
    }

    private printProgram(): void {
        const printContents = this.elem.innerHTML.replace(/d-none/g, '');
        const printWindow = window.open('', '', '_blank');

        printWindow.document.write(this.renderPrintPage(printContents));
        printWindow.focus();
        setTimeout(() => {
            printWindow.print();
            printWindow.close();
        }, 1000);
    }

    private renderPrintPage(html: string): string {
        return `
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <meta http-equiv="X-UA-Compatible" content="ie=edge">
            <title>Sample Track for ${this.title} - ${this.pathwayCode}</title></title>
            <link media="all" rel="stylesheet" href="/_resources/css/bootstrap-reboot.min.css">
            <link media="all" rel="stylesheet" href="/_resources/css/bootstrap-grid.min.css">
            <link media="all" rel="stylesheet" href="/_resources/css/bootstrap.min.css">
            <link media="all" rel="stylesheet" href="/_resources/css/styles.min.css">
            <link media="all" rel="stylesheet" href="/_resources/css/pathway-print.css">
        </head>
        <body>
            <h1 class="text-center">Indian River State College</h1>
            ${html}
        </body>
        </html>`;
    }
}
