'use strict';
import { GuidedPathway } from './irsc-guided-pathway';
import { Category } from './irsc-program-categories';
import { ProgramLevel } from './irsc-program-levels';
let lfRetryCount = 0;

async function getFilters() {
    return await fetch('/_resources/js/clock-hours-filter.json')
    .then((d) => d.json())
    .then((d) => d.filter);
}

const filters = getFilters();

function getDomNodeArray(selector: string) {
    // get the elements as a DOM collection
    const elemCollection = document.querySelectorAll(selector);
    // coerce the DOM collection into an array
    return Array.prototype.slice.apply(elemCollection);
}

async function setCreditHoursTitle(programCode: string): Promise<string> {
    const tmpFilters = await filters;

    if (programCode.includes('-')) {
        programCode = programCode.split('-')[0];
    }

    if (tmpFilters.includes(+programCode)) {
        return 'Clock Hours';
    }

    return 'Credits';
}

function afterDOMLoaded() {
    if (!(window as any).localforage && lfRetryCount <= 3) {
        lfRetryCount++;
        setTimeout(afterDOMLoaded, 0);
    } else {
        // ensure that CustomEvent is available for IE (if any users.... :/)
        (() => {
            if ( typeof (window as any).CustomEvent === 'function' ) {
                return false;
            }

            function CustomEvent( event, params ) {
              params = params || { bubbles: false, cancelable: false, detail: undefined };
              const evt = document.createEvent( 'CustomEvent' );
              evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
              return evt;
             }

            CustomEvent.prototype = (window as any).Event.prototype;
            (window as any).CustomEvent = CustomEvent;
        })();

        const $irscCategories = document.querySelector('#program-categories');
        const $programLevels = getDomNodeArray('.program-levels[data-credential]');
        const $guidedPathways = getDomNodeArray('[data-pathway-code]');

        // load the Category class
        if ($irscCategories) {
            const irscCategoriesManager = [];
            irscCategoriesManager.push(
                new Category($irscCategories as HTMLElement, {
                    irscCategoryImgSectionTemplate: {
                        id: '#irsc-category-img-section-tmp',
                        props: [
                            'categories',
                        ],
                    },
                    irscProgramCategoryTemplate: {
                        id: '#irsc-program-category-tmp',
                        props: [
                            'slug',
                            'name',
                            'description',
                            'imgSrc',
                            'imgAlt',
                        ],
                    },
                }),
            );
            console.log(irscCategoriesManager);
        }

        // load the ProgramLevel class
        // dependacy inject Chart as is only used for the rotator class but that class is
        // only used within ProgramLevel Class -> inject into ProgramLevel the down to Rotator
        // TODO: Maybe make a dependacy manager then cherry pick the deps as needed
        if ($programLevels && (window as any).Chart !== null) {
            const programLevelsManager = [];
            $programLevels.forEach((programLevel: HTMLElement, idx: number) => {
                programLevelsManager.push(
                    new ProgramLevel(
                        (window as any).Chart,
                        programLevel,
                        {
                            idx,
                            levelTemplate: {
                                id: '#irsc-level-card-tmp',
                                props: [
                                    'id',
                                    'credential',
                                ],
                            },
                        },
                    ),
                );
            });
        }

        if ($guidedPathways.length > 0) {
            const guidedPathwayManager = [];
            $guidedPathways.forEach(async (guidedPathway: HTMLElement, idx: number) => {
                guidedPathwayManager.push(
                    new GuidedPathway(guidedPathway, {
                        creditHoursTitle: await setCreditHoursTitle(guidedPathway.dataset.pathwayCode),
                        guidedPathwayTemplate: {
                            id: '#irsc-pathway-tmp',
                            props: [
                                'toggleId',
                                'tracks',
                            ],
                        },
                        idx,
                        paginationPageTemplate: {
                            id: '#irsc-pagination-page-tmp',
                            props: [
                                'active',
                                'pageNum',
                            ],
                        },
                        paginationPagesTemplate: {
                            id: '#irsc-pagination-pages-tmp',
                            props: [
                                'pages',
                                'totalCreditHours',
                            ],
                        },
                        pathwayTrackSemesterCourseTemplate: {
                            id: '#irsc-pathway-track-semester-course-tmp',
                            props: [
                                'courseId',
                                'title',
                                'creditHours',
                                'prerequisites',
                                'isRequired',
                            ],
                        },
                        pathwayTrackSemesterTemplate: {
                            id: '#irsc-pathway-track-semester-tmp',
                            props: [
                                'trackTitle',
                                'creditHoursTitle',
                                'page',
                                'courses',
                                'totalCreditHours',
                                'trackNotes',
                            ],
                        },
                    }),
                );
            });
            console.log(guidedPathwayManager);
        }

    }
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', afterDOMLoaded);
} else {
    afterDOMLoaded();
}
