'use strict';
import { IrscCache } from './irsc-cache';
import { Rotator } from './irsc-rotator';

export class ProgramLevel extends IrscCache(Object) {
    constructor(Chart: any, $elem: HTMLElement, opts: any) {
        super();
        this.Chart = Chart;
        this.elem = $elem;
        this.credential = this.elem.dataset.credential;
        this.program = this.elem.dataset.program || null;
        this.dataUrl = `/_resources/data/emsicc-service.php?action=program_levels&levels=${this.credential}`;
        this.jsonKey = 'programLevels';
        this.key = `program-level-${this.credential}`;
        this.opts = opts;
        this.idx = this.opts.idx;
        this.template = this.opts.levelTemplate;
        this.careerIdx = null;
        this.populateProgramLevel();
        this.cardRotators = null;
        this.loadDataEvent = new Event(`${this.key}-load`);
        this.elem.addEventListener(this.loadDataEvent, this.data);
    }

    private async data() {
        await this.get();
    }

    private renderLevelCard(program: any, idx: number) {
        const id = `card-${this.idx}-${this.credential}-${idx}`;
        let templateHtml = document.querySelector(this.template.id).innerHTML;

        templateHtml = templateHtml.replace(new RegExp(`{{id}}`, 'g'), id);

        this.template.props.forEach((prop) => {
            templateHtml = templateHtml.replace(new RegExp(`{{${prop}}}`, 'g'), program[prop] || '');
        });
        return templateHtml;
    }

    private async populateProgramLevel() {
        let levels: any = await this.get();
        levels = levels.data;

        if (levels && levels[this.credential]) {
            this.elem.innerHTML = this.program ?
                levels[this.credential]
                    .filter((program: any, idx: number) => {
                        if (`${this.program}-${this.credential}` === program.id) {
                            this.careerIdx = idx;
                        }
                        return `${this.program}-${this.credential}` === program.id;
                    })
                    .map((program: any, idx: number) => {
                        return this.renderLevelCard(program, idx);
                    }).join('')
                :
                levels[this.credential]
                    .map((program: any, idx: number) => this.renderLevelCard(program, idx)).join('');

            const cards = Array.prototype.slice.apply(this.elem.querySelectorAll('.card-body'));

            if (cards.length === 0) {
                this.elem.innerHTML = `
                <p style="background-color: #f8f8f8; padding: 1rem;box-shadow: 0 .25rem .5rem rgba(22,22,22,.2)">
                    For wage and employment data for careers related to ${this.program}, visit
                    <a href="https://irsc.emsicc.com/browse-careers?region=Fort%20Pierce,%20FL&radius=">Career Coach</a>
                </p>`;
            } else {
                this.cardRotators = [];

                cards.forEach((card: any, idx: number) => {
                    this.cardRotators.push(
                        new Rotator(this.Chart, card, {
                            careerIndex: this.careerIdx || idx,
                            controlsTemplate: {
                                id: '#irsc-card-rotator-controls-tmp',
                                props: [],
                            },
                            credential: this.credential,
                            jobListingLimit: 10,
                            jobListingTemplate: {
                                id: '#irsc-card-rotator-jobs-tmp',
                                props: [
                                    'name',
                                    'postings',
                                ],
                            },
                            loadParentDataEvent: this.loadDataEvent,
                            parentKey: this.key,
                            projectionId: `projection-${this.idx}-${idx}`,
                            slidesTemplate: {
                                id: '#irsc-card-rotator-slide-tmp',
                                props: [
                                    'title',
                                    'earnings',
                                    'jobListing',
                                    'projectionId',
                                ],
                            },
                        }),
                    );
                });
            }

        } else {
            console.log('invalid level and credential', levels, this.credential);
        }
    }
}
