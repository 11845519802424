'use strict';

import localForage from 'localforage';
import * as moment from 'moment';

const store = localForage.createInstance({
    name: 'irsc',
});
export const IrscCache = (base: any) => class extends base {
    constructor() {
        super();
        this.store = store;
        this.years = 0; // in years
        this.quarters = 0; // in quarters
        this.months = 0; // in months
        this.weeks = 0; // in weeks
        this.days = 1; // in days
        this.hours = 0; // in hours
        this.minutes = 0; // in minutes
        this.seconds = 0; // in seconds
        this.milliseconds = 0; // in milliseconds
    }

    public getMomentConfig() {
        return {
            days: this.days,
            hours: this.hours,
            milliseconds: this.milliseconds,
            minutes: this.minutes,
            months: this.months,
            quarters: this.quarters,
            seconds: this.seconds,
            weeks: this.weeks,
            years: this.years,
        };
    }

    public async fetch(key: string, url: string, jsonKey: string) {
        return fetch(url)
            .then((res) => res.json())
            .then((res) => {
                const data = {
                    data: res[jsonKey],
                    expiresIn: moment(Date.now()).add(this.getMomentConfig()).format(),
                    meta: {
                        func: 'fetch',
                        jsonKey,
                        key,
                        url,
                    },
                };

                return this.save(key, data).then(() => {
                    console.log(`Fetched data for ${key}`);
                })
                .catch((err) => {
                    console.log(`Could not save ${key}.`);
                    console.log(err);
                });
            })
            .catch((err) => {
                console.log('There was an error with the custom fetch request:');
                console.log(err);
            });
    }

    public async getData() {
        console.log('hello from getData');
        console.log('dataUrl:', this.dataUrl);
        return fetch(this.dataUrl)
        .then((res) => res.json())
        .then((res) => {
            console.log(res);
            return res;
        })
        .then((res) => {
            const data = {
                data: res[this.jsonKey],
                expiresIn: moment(Date.now()).add(this.getMomentConfig()).format(),
                meta: {
                    func: 'getData',
                    jsonKey: this.jsonKey,
                    key: this.key,
                    url: this.dataUrl,
                },
            };

            console.log(data);

            return this.save(this.key, data).then(() => {
                console.log(`Fetched data for ${this.jsonKey}.`);
            })
            .catch((err) => {
                console.log(`Could not save ${this.key}.`);
                console.log(err);
            });
        })
        .catch((err) => {
            console.log('There was an error with the getData fetch request:');
            console.log(err);
        });
    }

    public async getOther(key: string) {
        const data = await this.store.getItem(key);
        if (data !== null && data.expiresIn !== null && moment(Date.now()).isAfter(data.expiresIn) || !data) {
            console.log(`Expired, Stale Data! Key: ${key}`);
            return null;
        }
        return data;
    }

    public async get(key: string = null) {
        key = key || this.key;
        let cache = await this.store.getItem(key);
        if (!cache || moment(Date.now()).isAfter(cache.expiresIn)) {
            await this.getData();
            cache = await this.store.getItem(key);
        }
        return cache;
    }

    public async save(key: string, value: any) {
        key = key || this.key;

        if (!value.expiresIn) {
            const data = {
                data: value,
                expiresIn: moment(Date.now()).add(this.getMomentConfig()).format(),
                meta: {
                    func: 'save',
                    jsonKey: null,
                    key: null,
                    url: null,
                },
            };
            value = data;
        }

        return this.store.setItem(key, value);
    }

    public async remove() {
        return this.store.removeItem(this.key);
    }
};
